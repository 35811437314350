import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";
import React from "react";

export default function Laurels({ laurels }: { laurels: GatsbyImageProps["image"][] }) {
  return (
    <>
      {laurels.map((url, id) => (
        <GatsbyImage className="w-12 md:w-20" key={id} alt="" image={url} />
      ))}
    </>
  );
}
