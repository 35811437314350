import PropTypes from "prop-types";
import React, { PropsWithChildren } from "react";
import { useLocationInfo } from "../hooks";
import { Footer } from "./Footer";
import Header, { HeaderProps } from "./header";
import { LocaleContextProvider } from "./LanguageMenu";
import "./layout.css";
import Menu, { MenuContextProvider } from "./Menu";
import { RegionContextProvider, useCurrentRegion, useRegions } from "./Region";
import { Donate, DonateContextProvider } from "./Donate";
import { CookiesProvider } from "react-cookie";
import CookieConsent from "./CookieConsent";
import CTAPopup from "./TicketsPopup";

interface LayoutProps {
  headerProps?: HeaderProps;
  location: Location;
}

const Layout = ({ children, headerProps, location, ...props }: JSX.IntrinsicElements["div"] & LayoutProps) => {
  return (
    <>
      <div {...props}>
        <div className="font-roboto">
          <Header {...headerProps} location={location} />
          <Menu />
          {children}
          <Footer />
        </div>
      </div>
      <CTAPopup />
      <CookieConsent />
    </>
  );
};

export const Providers = ({ children, location }: PropsWithChildren<{ location: Location }>) => {
  return (
    <CookiesProvider>
      <LocaleContextProvider location={location}>
        <RegionContextProvider>
          <MenuContextProvider>
            <DonateContextProvider>{children}</DonateContextProvider>
          </MenuContextProvider>
        </RegionContextProvider>
      </LocaleContextProvider>
    </CookiesProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
