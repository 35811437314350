// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-behind-the-scenes-tsx": () => import("./../../../src/pages/behind-the-scenes.tsx" /* webpackChunkName: "component---src-pages-behind-the-scenes-tsx" */),
  "component---src-pages-cast-tsx": () => import("./../../../src/pages/cast.tsx" /* webpackChunkName: "component---src-pages-cast-tsx" */),
  "component---src-pages-directors-vision-tsx": () => import("./../../../src/pages/directors-vision.tsx" /* webpackChunkName: "component---src-pages-directors-vision-tsx" */),
  "component---src-pages-festival-screenings-tsx": () => import("./../../../src/pages/festival-screenings.tsx" /* webpackChunkName: "component---src-pages-festival-screenings-tsx" */),
  "component---src-pages-fight-for-love-tsx": () => import("./../../../src/pages/fight-for-love.tsx" /* webpackChunkName: "component---src-pages-fight-for-love-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inquiries-and-faqs-tsx": () => import("./../../../src/pages/inquiries-and-faqs.tsx" /* webpackChunkName: "component---src-pages-inquiries-and-faqs-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sergeys-story-tsx": () => import("./../../../src/pages/sergeys-story.tsx" /* webpackChunkName: "component---src-pages-sergeys-story-tsx" */),
  "component---src-pages-the-book-tsx": () => import("./../../../src/pages/the-book.tsx" /* webpackChunkName: "component---src-pages-the-book-tsx" */),
  "component---src-pages-watch-now-tsx": () => import("./../../../src/pages/watch-now.tsx" /* webpackChunkName: "component---src-pages-watch-now-tsx" */),
  "component---src-templates-watch-now-influencer-tsx": () => import("./../../../src/templates/WatchNowInfluencer.tsx" /* webpackChunkName: "component---src-templates-watch-now-influencer-tsx" */)
}

