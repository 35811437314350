import { addDays, addYears } from "date-fns/esm";
import { graphql, useStaticQuery } from "gatsby";
import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";
import { filterByLocale, useFilteredByLocale, useLocationInfo } from "../hooks";
import { useLocale, useLocaleContext } from "./LanguageMenu";
export const RegionContext = createContext({ region: {} } as {
  region: Region;
});

export const useRegion = () => {
  const { region } = useContext(RegionContext);
  return region;
};

export const useCurrentRegion = () => {
  const regions = useRegions();
  const locationInfo = useLocationInfo();
  const defaultRegions = regions.filter(({ defaultRegion }) => defaultRegion);
  if (locationInfo === null) return defaultRegions;

  const { region } = regions.reduce(
    (acc: { matches: number; region: Region }, curr: Region) => {
      const matches = [
        { region: "city", location: "city" },
        { region: "country", location: "country" },
        { region: "county", location: "regionName" },
      ].reduce((m, { location, region }) => {
        const regionParam = curr[region as keyof Region];
        const locationParam = locationInfo[location as keyof UserLocation];
        if (!regionParam || !locationParam) return m;
        if ((regionParam as string | undefined)?.includes(locationParam)) return m + 1;

        return m;
      }, 0);

      return acc.matches < matches ? { matches, region: curr } : acc;
    },
    { matches: 0, region: {} } as {
      matches: number;
      region: Region;
    }
  );

  if (!region.name) return defaultRegions;

  return regions.filter(({ name }) => region.name.includes(name));
  // return regions.filter(({ name }) => name === "Germany");
  // return regions.filter(({ name }) => name === "United States");
  // return regions.filter(({ name }) => name === "United Kingdom");
};

export const RegionContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const currentRegion = useCurrentRegion();
  const { locale, setLocale } = useLocaleContext();
  const [{ locale_set }, setCookie] = useCookies(["locale_set"]);

  useEffect(() => {
    if (locale_set) return;
    if (currentRegion.some(({ defaultRegion }) => !!defaultRegion)) return;

    const preferredRegion = currentRegion.find((region) => region.locale === region.defaultLocale);
    if (!preferredRegion) return;

    setLocale(preferredRegion.locale);
    setCookie("locale_set", true, { path: "/", expires: addDays(new Date(), 1) });
  }, [currentRegion]);

  const region = filterByLocale(currentRegion, locale);

  const context = useMemo(() => {
    return {
      region: region[0],
    };
  }, [region]);

  return <RegionContext.Provider value={context}>{children}</RegionContext.Provider>;
};

export const useRegions = (): Region[] => {
  const {
    allStrapiRegions: { edges },
  } = useStaticQuery<{ allStrapiRegions: EdgesQuery<{ node: Region }> }>(query);

  return edges.map(({ node }) => node);
};

const query = graphql`
  query RegionQuery {
    allStrapiRegions {
      edges {
        node {
          id
          name
          defaultRegion
          inTheatersAndOnDemand
          locale
          defaultLocale
          trailerUrl
          showPlayerHeader
          pantaflixPlayerHeaderText
          pantaflixPlayerUrl
          pantaflixPlayerTVSubtext
          partnerSitesText
          sellingPoints {
            title
            subtitle
          }
          moviePoster {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          firebirdLogo {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, formats: [AUTO, WEBP])
              }
            }
          }
          byPeeterRebane {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, formats: [AUTO, WEBP])
              }
            }
          }
          watchNowBanner {
            title
            body
            button
            cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
          }
          country
          streamingPlatforms {
            url
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, formats: [AUTO, WEBP])
                }
              }
            }
            comingSoon
          }
          showTicketsPopup
          ticketsUrl
          ticketsButtonText
        }
      }
    }
  }
`;
