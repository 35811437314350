import { SectionBanner, useHomepage } from "../../pages";
import React from "react";
import { ButtonLink } from "../Button";
import { CommonTranslation } from "../CommonTranslations";

export default function ShopBanner() {
  const { shop, shopUrl } = useHomepage();
  return (
    <SectionBanner banner={shop}>
      <div className="mt-8">
        <ButtonLink className="inline py-2" to={shopUrl}>
          <CommonTranslation value="shop" />
        </ButtonLink>
      </div>
    </SectionBanner>
  );
}
