import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo } from "react";
import { filterByLocale } from "../hooks";
import { useLocale } from "./LanguageMenu";

export type CommonTranslations = {
  home: string;
  buyTickets: string;
  cast: string;
  city: string;
  dates: string;
  directedBy: string;
  directorsVision: string;
  donateAndBehindTheScenes: string;
  behindTheScenes: string;
  email: string;
  event: string;
  festivalScreenings: string;
  findOutMore: string;
  firstName: string;
  locale: string;
  location: string;
  meetTheCast: string;
  pastFestivals: string;
  pressAreaAndContact: string;
  privacyPolicy: string;
  producedBy: string;
  readMore: string;
  rentNow: string;
  sergeysStory: string;
  share: string;
  starring: string;
  stayInTouch: string;
  subscribe: string;
  thankYouForSupportingDirectly: string;
  tickets: string;
  troubleRentingMovieFAQ: string;
  watchNow: string;
  watchTrailer: string;
  websiteTerms: string;
  writtenAndProducedBy: string;
  viewMore: string;
  laurels: string;
  onlyEmailIsMandatory: string;
  stayInTouchSubtext: string;
  basedOnATrueStory: string;
  donate: string;
  comingSoon: string;
  firebird: string;
  vodTerms: string;
  helpCenter: string;
  moreInfo: string;
  getNotified: string;
  fightForLove: string;
  getTickets: string;
  director: string;
  send: string;
  message: string;
  isRequired: string;
  watchMore: string;
  shop: string;
  giveBack: string;
  theBook: string;
};

export const useCommonTranslations = (): CommonTranslations => {
  const locale = useLocale();

  const {
    allStrapiCommonTranslations: { edges },
  } = useStaticQuery<{ allStrapiCommonTranslations: EdgesQuery<{ node: CommonTranslations }> }>(query);

  return useMemo(() => {
    return filterByLocale(
      edges.map(({ node }) => ({ ...node })),
      locale
    )[0];
  }, [locale]);
};

export const CommonTranslation = ({ value }: { value: keyof CommonTranslations }) => {
  const commonTranslations = useCommonTranslations();
  const translation = commonTranslations[value];

  return <>{translation && translation.length > 0 ? translation : value}</>;
};

const query = graphql`
  query MyQuery {
    allStrapiCommonTranslations {
      edges {
        node {
          home
          buyTickets
          cast
          city
          dates
          directedBy
          directorsVision
          donateAndBehindTheScenes
          behindTheScenes
          email
          event
          festivalScreenings
          findOutMore
          firstName
          locale
          location
          meetTheCast
          pastFestivals
          pressAreaAndContact
          privacyPolicy
          producedBy
          readMore
          rentNow
          sergeysStory
          share
          starring
          stayInTouch
          subscribe
          thankYouForSupportingDirectly
          tickets
          troubleRentingMovieFAQ
          watchNow
          watchTrailer
          websiteTerms
          writtenAndProducedBy
          viewMore
          laurels
          onlyEmailIsMandatory
          stayInTouchSubtext
          basedOnATrueStory
          donate
          comingSoon
          firebird
          vodTerms
          helpCenter
          moreInfo
          getNotified
          fightForLove
          getTickets
          director
          send
          message
          isRequired
          watchMore
          shop
          giveBack
          theBook
        }
      }
    }
  }
`;
