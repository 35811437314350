import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocale } from "./LanguageMenu";

export const constructUrl = (baseUrl, path) => (!baseUrl || !path ? null : `${baseUrl}${path}`);

function Seo({ description, lang, meta, title }) {
  const {
    site,
    strapiMarketing: { ogTitle, ogDescription, metaDescription, twitterTitle, twitterDescription, ogImage },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        strapiMarketing {
          ogImage {
            localFile {
              publicURL
            }
          }
          ogTitle
          ogDescription
          description
          twitterTitle
          twitterDescription
        }
      }
    `
  );
  const defaultTitle = site.siteMetadata?.title;

  const imageUrl = constructUrl(site.siteMetadata.siteUrl, ogImage?.localFile?.publicURL);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: twitterTitle,
        },
        {
          name: `twitter:description`,
          content: twitterDescription,
        },
      ].concat(meta)}
    />
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
