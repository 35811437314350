import cx from "classnames";
import { addMinutes } from "date-fns";
import { navigate } from "gatsby";
import { PropsWithChildren, useRef } from "react";
import { useCookies } from "react-cookie";
import Close from "../images/icons/close-primary.svg";
import Image from "../images/tickets-banner.jpg";
import { useHomepage } from "../pages";
import useOnClickOutside from "../utils/use-click-outside";
import Button from "./Button";
import { CommonTranslation } from "./CommonTranslations";
import { LocaleAwareLink } from "./LanguageMenu";
import { useRegion } from "./Region";
import React from "react";

export default function CTAPopup() {
  const [{ ctaPopup }, setCookie] = useCookies(["ctaPopup"]);

  const ref = useRef(null);
  useOnClickOutside(ref, () => ctaPopup != "dismissed" && dismiss());

  const dismiss = () => setCookie("ctaPopup", "dismissed", { expires: addMinutes(new Date(), 30) });

  if (ctaPopup === "dismissed") return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-[70]">
      <div
        ref={ref}
        className="flex items-center justify-center w-full mx-4 overflow-hidden text-white bg-black border-2 border-white rounded-lg md:w-full lg:w-1/2 md:mx-4 lg:mx-auto"
      >
        <div className="relative flex flex-col w-full h-full">
          <img src={Image} className="hidden md:block" />
          <img src={Image} className="block md:hidden min-h-[80vh] object-cover" />
          <div
            className="absolute top-0 left-0 flex flex-col w-full h-full pb-2"
            style={{ background: "linear-gradient(to bottom, transparent, rgba(0,0,0,0.7) 100%)" }}
          >
            <div className="flex items-center justify-end w-full p-2">
              <button onClick={dismiss} className="p-1">
                <img src={Close} />
              </button>
            </div>
            <div className="flex-grow"></div>
            <div className="flex flex-col items-center w-full">
              <PopupButtons dismiss={dismiss} />
              <Button
                className="justify-center h-10 text-xs border-0 border-primary hover:opacity-80"
                onClick={dismiss}
              >
                Continue to homepage
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const PopupButtons = ({ dismiss }: { dismiss: () => void }) => {
  const { showTicketsPopup, ticketsButtonText, ticketsUrl } = useRegion();
  const { giveBackUrl, shopUrl } = useHomepage();

  if (showTicketsPopup)
    return (
      <div className="w-full md:w-1/2">
        <PopupButtonLink className="mx-auto" to={ticketsUrl ?? ""}>
          {ticketsButtonText ?? "tickets"}
        </PopupButtonLink>
      </div>
    );

  return (
    <div className="flex flex-col items-center justify-center w-full px-2 md:w-3/4 gap-x-4 gap-y-2 md:flex-row">
      <button
        onClick={() => {
          dismiss();
          navigate("/watch-now");
        }}
        className={popupButtonClasses}
      >
        <CommonTranslation value="watchNow" />
      </button>
      <PopupButtonLink onClick={dismiss} to="/the-book">
        <CommonTranslation value="theBook" />
      </PopupButtonLink>
      <PopupButtonLink onClick={dismiss} to={shopUrl}>
        <CommonTranslation value="shop" />
      </PopupButtonLink>
    </div>
  );
};

const popupButtonClasses =
  "flex items-center justify-center flex-1 w-1/2 py-3 text-sm font-bold uppercase bg-black rounded-lg md:w-full hover:opacity-90 from-primary to-primaryDark bg-gradient-to-r";

const PopupButtonLink = ({
  children,
  onClick,
  to,
  className,
}: PropsWithChildren<{ onClick?: () => void; to: string; className?: string }>) => {
  return (
    <LocaleAwareLink to={to} onClick={onClick} className={cx(popupButtonClasses, className)}>
      {children}
    </LocaleAwareLink>
  );
};
