import { GatsbyLinkProps, graphql, Link, navigate, useStaticQuery } from "gatsby";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import qs from "query-string";
import ChevronDown from "../images/chevron-down.svg";
import cx from "classnames";
import { useCurrentRegion, useRegion, useRegions } from "./Region";

export const DEFAULT_LOCALE = "en";

export default function LanguageMenu() {
  const [open, setOpen] = useState(false);
  const { locale, setLocale } = useContext(LocaleContext);
  const regions = useCurrentRegion() ?? [];

  const {
    allStrapiI18NLocales: { edges: allLocales },
  } = useStaticQuery<{ allStrapiI18NLocales: { edges: Array<{ node: Language }> } }>(graphql`
    query LocalesQuery {
      allStrapiI18NLocales {
        edges {
          node {
            code
          }
        }
      }
    }
  `);

  // const regionLocales = regions.map((region) => region.locale);
  const locales = allLocales.map(({ node: { code } }) => code); /* .filter((code) => regionLocales.includes(code)); */

  const otherLocales = locales.filter((code) => code !== locale);

  return (
    <div className="relative h-full">
      <button
        type="button"
        className={cx("flex items-center", {
          "cursor-default": otherLocales.length === 0,
        })}
        onClick={() => otherLocales.length > 0 && setOpen(!open)}
      >
        <span className="font-semibold uppercase">{locale}</span>
        {otherLocales.length > 0 && <img src={ChevronDown} alt="chevron down" />}
      </button>
      {open && (
        <div className="absolute flex flex-col mt-2 space-y-2 text-white">
          {otherLocales.map((code) => (
            <div key={code}>
              <button
                type="button"
                className="flex items-center"
                onClick={() => {
                  setLocale(code);
                  setOpen(false);
                }}
              >
                <span className="font-semibold uppercase">{code}</span>
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export const LocaleContext = createContext({ locale: DEFAULT_LOCALE, setLocale: (locale) => {} } as {
  locale: string;
  setLocale: (locale: string) => void;
  location?: Location;
});

export const useLocale = () => {
  const { locale } = useContext(LocaleContext);
  return locale;
};

export const useLocaleContext = () => {
  return useContext(LocaleContext);
};

export const useLocation = () => {
  const { location } = useContext(LocaleContext);
  return location;
};

export const LocaleContextProvider = ({ location, children }: PropsWithChildren<{ location?: Location }>) => {
  const [locale, setLocale] = useQueryString("lang", DEFAULT_LOCALE) as [string, (loc: string) => void];

  const context = useMemo(
    () => ({
      locale,
      setLocale,
      location,
    }),
    [locale, setLocale, location]
  );

  return <LocaleContext.Provider value={context}>{children}</LocaleContext.Provider>;
};

export const LocaleAwareLink = (props: Omit<GatsbyLinkProps<Record<string, unknown>>, "ref">) => {
  const locale = useLocale();

  let to = "";
  if (locale === DEFAULT_LOCALE) {
    to = props.to;
  } else {
    if (props.to.indexOf("?") !== -1) {
      let params = qs.parse(props.to.split("?")[1]);
      params.lang = locale;
      to = props.to.split("?")[0] + "?" + qs.stringify(params);
    } else {
      to = props.to + "?lang=" + locale;
    }
  }

  return <Link {...props} to={to} />;
};

const setQueryStringWithoutPageReload = (qsValue: string) => {
  const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + qsValue;

  window.history.pushState({ path: newurl }, "", newurl);
};

const setQueryStringValue = (key: string, value: string, queryString?: string) => {
  if (typeof window === "undefined") return;
  const values = qs.parse(queryString || window.location.search);
  const newQsValue = qs.stringify({ ...values, [key]: value });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (key: string, queryString?: string) => {
  if (typeof window === "undefined") return;
  const values = qs.parse(queryString || window.location.search);
  return values[key];
};

export function useQueryString(key: string, initialValue: string): [string | string[], (newValue: any) => void] {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);

  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}
