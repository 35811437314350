import { SectionBanner, useHomepage } from "../../pages";
import React from "react";
import { ButtonLink } from "../Button";
import { CommonTranslation } from "../CommonTranslations";

export default function TheBookBanner() {
  const { theBook } = useHomepage();
  return (
    <SectionBanner banner={theBook}>
      <div className="mt-8">
        <ButtonLink className="inline py-2" to={"/the-book"}>
          {theBook.button}
        </ButtonLink>
      </div>
    </SectionBanner>
  );
}
