import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import queryString from "query-string";
import React from "react";
import { useFilteredByLocale } from "../../hooks";
import { CommonTranslation } from "../CommonTranslations";
import { LocaleAwareLink } from "../LanguageMenu";

const imageVariants = {
  rest: {
    opacity: 0.6,
    transition: { duration: 0.1 },
  },
  hover: {
    scale: 1.075,
    opacity: 1,
    transition: { duration: 0.4 },
  },
};

interface CastListProps {
  onClick?(id: string): void;
}

export const CastList = ({ onClick }: CastListProps) => {
  const actors = useCast();

  return (
    <div className="text-white py-20 w-2/3 md:w-5/6 mx-auto">
      <div className="text-xl md:text-3xl font-bold mb-8">
        <CommonTranslation value="meetTheCast" />
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
        {actors.map(({ realName, characterName, url, id }, index) => (
          <div key={realName}>
            <LocaleAwareLink to={"/cast?" + queryString.stringify({ actor: realName })} onClick={() => onClick?.(id)}>
              <motion.div className="mb-8 lg:mb-0 mx-2" whileHover="hover" initial="rest">
                <motion.div className="overflow-hidden rounded-xl">
                  <motion.div variants={imageVariants}>
                    <GatsbyImage alt={realName} image={url} />
                  </motion.div>
                </motion.div>
                <div className="px-2 mt-4">
                  <div className="text-xs md:text-lg font-bold">{realName}</div>
                  <div className="text-xs md:text-base font-light mt-2">{characterName}</div>
                </div>
              </motion.div>
            </LocaleAwareLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export const useCast = () => {
  const {
    allStrapiActors: { edges },
  } = useStaticQuery<{ allStrapiActors: EdgesQuery<{ node: Actor }> }>(query);

  const actors = edges.flatMap(
    ({
      node: {
        id,
        image: {
          localFile: {
            childImageSharp: { gatsbyImageData },
          },
        },
        order,
        locale,
        realName,
        characterName,
        paragraphs,
        links,
        socials,
      },
    }) => ({
      id,
      order,
      url: gatsbyImageData,
      locale,
      realName,
      characterName,
      paragraphs,
      links,
      socials,
    })
  );

  return (useFilteredByLocale(actors) as typeof actors).sort((a, b) => a.order - b.order);
};

const query = graphql`
  query CastQuery {
    allStrapiActors {
      edges {
        node {
          characterName
          order
          id
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          links {
            text
            url
          }
          locale
          paragraphs {
            text
          }
          realName
        }
      }
    }
  }
`;
