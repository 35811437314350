import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";
import React from "react";
import FilmBy from "../images/film-by.png";
import { useRegion } from "./Region";

export default function Logo(props: Omit<GatsbyImageProps, "alt" | "image">) {
  const { firebirdLogo } = useRegion();

  if (!firebirdLogo) return null;

  return <GatsbyImage alt="firebird logo" image={firebirdLogo.localFile.childImageSharp.gatsbyImageData} {...props} />;
}

export const Author = (props: Omit<GatsbyImageProps, "alt" | "image">) => {
  const { byPeeterRebane } = useRegion();

  if (!byPeeterRebane) return null;

  return (
    <GatsbyImage
      alt="a film by peeter rebane"
      image={byPeeterRebane.localFile.childImageSharp.gatsbyImageData}
      objectFit="contain"
      {...props}
    />
  );
};

export const LogoWithAuthor = (props: any) => {
  return (
    <div className="w-full">
      <div className="flex justify-center mb-3">
        <Author className="w-[20%]" />
      </div>
      <div className="mx-auto">
        <Logo />
      </div>
    </div>
  );
};
