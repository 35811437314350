import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { useFilteredByLocale } from "../hooks";

export default function Socials() {
  const socials = useSocials();

  return (
    <>
      {socials
        .sort((a, b) => a.order - b.order)
        .map(({ altText, imageUrl, socialUrl }) => (
          <a key={imageUrl} href={socialUrl} target="_blank">
            <img alt={altText} src={imageUrl} />
          </a>
        ))}
    </>
  );
}

export const useSocials = () => {
  const {
    allStrapiSocials: { edges },
  } = useStaticQuery<{ allStrapiSocials: EdgesQuery<{ node: Social }> }>(query);

  const socials = edges.map(
    ({
      node: {
        altText,
        socialUrl,
        order,
        locale,
        icon: {
          localFile: { publicURL: imageUrl },
        },
      },
    }) => ({ altText, imageUrl, socialUrl, order, locale })
  );

  return useFilteredByLocale(socials);
};

const query = graphql`
  query Socials {
    allStrapiSocials {
      edges {
        node {
          altText
          socialUrl
          order
          locale
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
