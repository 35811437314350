import { getYear } from "date-fns";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { CommonTranslation } from "./CommonTranslations";
import { LocaleAwareLink } from "./LanguageMenu";
import Socials from "./Socials";

export const Footer = () => {
  const { strapiTermsAndConditions, strapiVodTermsAndConditions, strapiPrivacyPolicy } = useStaticQuery<{
    strapiTermsAndConditions: { pdf: { localFile: { publicURL: string } } };
    strapiVodTermsAndConditions: { pdf: { localFile: { publicURL: string } } };
    strapiPrivacyPolicy: { pdf: { localFile: { publicURL: string } } };
  }>(query);

  const year = getYear(new Date());

  return (
    <div className="flex flex-col items-center w-full space-y-4 bg-[#1E1E1E] py-2 px-2 font-light font-helvetica text-white text-sm text-center md:flex-row md:space-y-0 md:justify-between md:p-4">
      <div className="flex order-2 space-x-4 md:order-1 lg:space-x-6">
        <Socials />
      </div>
      <div className="flex order-1 pb-4 space-x-6 md:pb-0">
        <div className="flex flex-col space-y-2 text-center underline uppercase md:flex-row md:space-y-0 md:space-x-2 lg:space-x-4">
          <LocaleAwareLink to="/inquiries-and-faqs#help-center">
            <CommonTranslation value="helpCenter" />
          </LocaleAwareLink>
          <a href={strapiPrivacyPolicy.pdf.localFile.publicURL} target="_blank">
            <CommonTranslation value="privacyPolicy" />
          </a>
          <a href={strapiTermsAndConditions.pdf.localFile.publicURL} target="_blank">
            <CommonTranslation value="websiteTerms" />
          </a>
          <a href={strapiVodTermsAndConditions.pdf.localFile.publicURL} target="_blank">
            <CommonTranslation value="vodTerms" />
          </a>
        </div>
        <div className="hidden md:block">© {year} Lucky Laika Distribution OÜ</div>
      </div>
      <div className="order-3 md:hidden">© {year} Lucky Laika Distribution OÜ</div>
    </div>
  );
};

const query = graphql`
  query TermsAndConditions {
    strapiTermsAndConditions {
      pdf {
        localFile {
          publicURL
        }
      }
    }
    strapiVodTermsAndConditions {
      pdf {
        localFile {
          publicURL
        }
      }
    }
    strapiPrivacyPolicy {
      pdf {
        localFile {
          publicURL
        }
      }
    }
  }
`;
