import cx from "classnames";
import { endOfDay, format, isAfter, isBefore } from "date-fns";
import * as Locales from "date-fns/locale";
import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo, useState } from "react";
import { useFilteredByLocale } from "../hooks";
import Launch from "../images/icons/launch.svg";
import { CommonTranslation } from "./CommonTranslations";
import { useLocale } from "./LanguageMenu";

export const Screenings = ({ all = true }: { all?: boolean }) => {
  const screenings = useScreenings();

  const today = endOfDay(new Date());

  const filteredScreenings = screenings
    .filter((screening) => {
      if (!screening.endDate) {
        const start = endOfDay(screening.startDate);
        return isBefore(today, start);
      }

      const end = endOfDay(screening.endDate);
      return isBefore(today, end);
    })
    .sort(({ startDate: a }, { startDate: b }) => a.getTime() - b.getTime());

  const screens = all ? filteredScreenings : filteredScreenings.slice(0, 5);
  const formatDate = useFormatDate();

  return (
    <div className="text-white w-full px-4 sm:w-2/3 md:w-5/6 mx-auto overflow-x-auto">
      <div className="text-xl md:text-3xl font-bold mb-8">
        <CommonTranslation value="festivalScreenings" />
      </div>
      <table className="hidden md:table font-helvetica lg:w-full">
        <thead>
          <tr>
            <th>
              <CommonTranslation value="event" />
            </th>
            <th>
              <CommonTranslation value="location" />
            </th>
            <th>
              <CommonTranslation value="dates" />
            </th>
            <th>
              <CommonTranslation value="tickets" />
            </th>
          </tr>
        </thead>
        <tbody>
          {screens.map(({ eventName, startDate, endDate, location, url }, i) => (
            <tr key={i} className="border-white border-t">
              <td className="font-bold">{eventName}</td>
              <td>{location}</td>
              <td>{formatDate(startDate)}</td>
              <td className="pt-0 pb-0">
                <a href={url} target="_blank" className="py-4">
                  <CommonTranslation value="buyTickets" />
                </a>
              </td>
            </tr>
          ))}
          {filteredScreenings.length === 0 && (
            <tr className="border-gray-200 border-t">
              <td colSpan={4} className="text-center">
                --
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <ScreeningsCompact className="md:hidden" screenings={screens} />
    </div>
  );
};

const ScreeningsCompact = ({ screenings, className }: { screenings: Screening[]; className?: string }) => {
  const [selected, setSelected] = useState<number | null>(null);
  const formatDate = useFormatDate();

  return (
    <div className={cx("flex flex-col w-full", className)}>
      {screenings.map(({ eventName, startDate, location, url }, i) => (
        <a key={i} href={url} target="_blank">
          <div className="flex items-center justify-between py-4 border-t border-white text-xs">
            <div>
              <p className="font-semibold">{eventName}</p>
              <p>{location}</p>
              <p>{formatDate(startDate)}</p>
            </div>
            <img src={Launch} className="w-5 h-5" />
          </div>
        </a>
      ))}
      {screenings.length === 0 && <p className="">--</p>}
    </div>
  );
};

export const PastScreenings = () => {
  const screenings = useScreenings();

  const today = endOfDay(new Date());

  const filteredScreenings = screenings
    .filter((screening) => {
      if (!screening.endDate) {
        const start = endOfDay(screening.startDate);
        return isAfter(today, start);
      }

      const end = endOfDay(screening.endDate);
      return isAfter(today, end);
    })
    .sort(({ startDate: a }, { startDate: b }) => a.getTime() - b.getTime());

  const formatDate = useFormatDate();

  return (
    <div className="text-white w-full px-4 sm:w-2/3 md:w-5/6 mx-auto">
      <div className="text-xl md:text-3xl font-bold mb-8">
        <CommonTranslation value="pastFestivals" />
      </div>
      <table className="hidden md:table font-helvetica w-full">
        <thead>
          <tr>
            <th>
              <CommonTranslation value="event" />
            </th>
            <th>
              <CommonTranslation value="location" />
            </th>
            <th>
              <CommonTranslation value="dates" />
            </th>
            {/* <th>
              <CommonTranslation value="laurels" />
            </th> */}
          </tr>
        </thead>
        <tbody>
          {filteredScreenings.map(({ eventName, startDate, endDate, location }, i) => (
            <tr key={i} className="border-white border-t">
              <td className="font-bold">{eventName}</td>
              <td>{location}</td>
              <td>{formatDate(startDate)}</td>
              {/* <td className="pt-0 pb-0"></td> */}
            </tr>
          ))}
          {filteredScreenings.length === 0 && (
            <tr className="border-gray-200 border-t">
              <td colSpan={4} className="text-center">
                --
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <ScreeningsCompact screenings={filteredScreenings} className="md:hidden" />
    </div>
  );
};

const useFormatDate = () => {
  const locale = useLocale();

  return useMemo(() => {
    const dateLocale = locale === "en" ? Locales.enUS : Locales[locale];

    return (date: Date) => format(date, "PPP", { locale: Locales[locale] ?? Locales.enUS });
  }, [locale]);
};

export const useScreenings = (): Screening[] => {
  const {
    allStrapiScreenings: { edges },
  } = useStaticQuery<{ allStrapiScreenings: EdgesQuery<{ node: Screening }> }>(query);

  const screenings = edges.flatMap(({ node }) => ({
    ...node,
    startDate: new Date(node.startDate),
    endDate: node.endDate ? new Date(node.endDate) : null,
  }));

  return useFilteredByLocale(screenings);
};

const query = graphql`
  query ScreeningsQuery {
    allStrapiScreenings {
      edges {
        node {
          locale
          location
          eventName
          startDate
          endDate
          url
        }
      }
    }
  }
`;
