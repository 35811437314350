import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import Close from "../images/icons/close.svg";
import { useKeyPress } from "../utils/use-key-press";
import { CommonTranslation } from "./CommonTranslations";
import { useRegion } from "./Region";

const variants = {
  hidden: {
    background: "rgba(0, 0, 0, 0)",
  },
  shown: {
    background: "rgba(0, 0, 0, 1)",
  },
};

const iframeVariants = {
  hidden: {
    opacity: 0,
  },
  shown: {
    opacity: 1,
  },
};

export default function WatchTrailer() {
  const { trailerUrl } = useRegion();
  const [trailerOpen, setTrailerOpen] = useState<boolean>(false);
  const [{ cookie_consent }] = useCookies(["cookie_consent"]);
  useKeyPress("Escape", () => setTrailerOpen(false));

  return (
    <>
      {trailerUrl && (
        <button className="mt-3 underline text-white text-center text-sm mb-16" onClick={() => setTrailerOpen(true)}>
          <CommonTranslation value="watchTrailer" />
        </button>
      )}
      {!trailerUrl && <div className="mb-20" />}
      <AnimatePresence>
        {trailerOpen && (
          <motion.div
            className="fixed top-0 left-0 z-50 w-screen h-screen bg-black p-8"
            animate={trailerOpen ? "shown" : "hidden"}
            initial="hidden"
            variants={variants}
            transition={{ duration: 0.5 }}
          >
            <div className="flex w-full justify-end">
              <button type="button" onClick={() => setTrailerOpen(false)}>
                <img className="w-10 h-10" src={Close} alt="close" />
              </button>
            </div>
            <div className="py-2 md:p-16 w-full h-full">
              {cookie_consent && (
                <motion.div className="h-full rounded-lg" variants={iframeVariants} transition={{ delay: 0.5 }}>
                  <iframe
                    className="w-full h-full overflow-hidden"
                    typeof="text/html"
                    src={trailerUrl}
                    frameBorder="0"
                  ></iframe>
                </motion.div>
              )}
              {!cookie_consent && (
                <motion.div
                  className="h-full w-full flex items-center justify-center text-white"
                  variants={iframeVariants}
                  transition={{ delay: 0.5 }}
                >
                  <p>You haven't agreed to the use of cookies. The trailer is not available.</p>
                </motion.div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
