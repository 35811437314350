import { useEffect, useMemo, useState } from "react";
import { DEFAULT_LOCALE, useLocale } from "./components/LanguageMenu";
import { useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { number } from "prop-types";
import { differenceInHours } from "date-fns";
import { SentryException } from "./utils/SentryException";

export const useActors = (query: EdgesQuery<{ node: Actor }>) => {
  return query.edges.map(
    ({
      node: {
        realName,
        characterName,
        locale,
        image: {
          localFile: { publicURL },
        },
      },
    }) => ({ realName, characterName, url: publicURL, locale })
  );
};

export const useFilteredByLocale = <T extends Array<{ locale: string; [k: string]: any }>>(
  data: T,
  useDefaults = true
) => {
  const currentLocale = useLocale();

  return useMemo(() => filterByLocale(data, currentLocale, useDefaults), [data, currentLocale]);
};

export const filterByLocale = <T extends Array<any>>(data: T, currentLocale: string, useDefaults = true) => {
  const currentFiltered = data.filter(({ locale }) => locale === currentLocale);
  if (currentFiltered.length === 0 && useDefaults) return data.filter(({ locale }) => locale === DEFAULT_LOCALE);

  return currentFiltered as T;
};

export const useLocationInfo = () => {
  const [location, setLocation] = useState<UserLocation | null>(null);

  useEffect(() => {
    if (typeof localStorage !== "undefined" && localStorage !== null) {
      const localStore = localStorage.getItem("user_loc");

      if (localStore) {
        const localObj = JSON.parse(localStore) as UserLocation & { timestamp: string };
        setLocation(localObj);
        return;
      }
    }

    axios
      .get<UserLocation>("https://cms.firebirdmovie.com/location")
      .then(({ data }) => {
        if (data.status !== "success") throw new SentryException("IP-API request status not 'success'", data);

        // localStorage.setItem("user_loc", JSON.stringify({ ...data, timestamp: new Date().toISOString() }));
        setLocation(data);
      })
      .catch(() => {
        axios
          .get<{ city: string; region: string; country_name: string }>("https://ipapi.co/json/")
          .then(({ data }) => {
            setLocation({ city: data.city, country: data.country_name, regionName: data.region } as UserLocation);
          })
          .catch((err) => console.log(err));
      });
  }, []);

  return location;
};

function getWindowDimensions() {
  if (typeof window === "undefined") return { width: 1920, height: 1080 };
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
