import React from "react";
import Amex from "../images/amex.png";
import Mastercard from "../images/mastercard.png";
import PlayIcon from "../images/play-circle-filled.svg";
import Visa from "../images/visa.png";
import { SectionBanner, useHomepage } from "../pages";
import Banner from "./Banner";
import { ButtonLink } from "./Button";
import { CommonTranslation } from "./CommonTranslations";
import { useRegion } from "./Region";

// export const WatchNowBanner = ({ animate }: { animate?: boolean }) => {
//   const { watchNowBanner, pantaflixPlayerUrl, showTicketsPopup, ticketsUrl } = useRegion();

//   return (
//     <SectionBanner
//       banner={{
//         cover: watchNowBanner?.cover,
//         title: watchNowBanner.title,
//         body: watchNowBanner.body,
//       }}
//     >
//       <div className="flex flex-col mt-4 sm:flex-row gap-x-6">
//         <div>
//           <ButtonLink
//             to={showTicketsPopup && ticketsUrl ? ticketsUrl : "/watch-now"}
//             filled
//             className="inline-flex items-center py-1 pl-4 pr-8 space-x-2 font-roboto md:py-2"
//           >
//             <img src={PlayIcon} alt="Play" className="w-7 h-7" />
//             <div className="text-sm text-left text-black md:text-lg">
//               {!showTicketsPopup && <div className="font-bold leading-5">{watchNowBanner?.button}</div>}
//               {showTicketsPopup && (
//                 <div className="font-bold leading-5">
//                   <CommonTranslation value="getTickets" />
//                 </div>
//               )}
//             </div>
//           </ButtonLink>
//         </div>
//       </div>
//     </SectionBanner>
//   );
// };

const TicketsBanner = () => {
  const { watchNowBanner, showTicketsPopup, ticketsUrl } = useRegion();

  return (
    <SectionBanner
      banner={{
        cover: watchNowBanner?.cover,
        title: watchNowBanner.title,
        body: watchNowBanner.body,
      }}
    >
      <div className="flex flex-col mt-4 sm:flex-row gap-x-6">
        <div>
          <ButtonLink
            to={showTicketsPopup && ticketsUrl ? ticketsUrl : "/watch-now"}
            filled
            className="inline-flex items-center py-1 pl-4 pr-8 space-x-2 font-roboto md:py-2"
          >
            <img src={PlayIcon} alt="Play" className="w-7 h-7" />
            <div className="text-sm text-left text-black md:text-lg">
              {!showTicketsPopup && <div className="font-bold leading-5">{watchNowBanner?.button}</div>}
              {showTicketsPopup && (
                <div className="font-bold leading-5">
                  <CommonTranslation value="getTickets" />
                </div>
              )}
            </div>
          </ButtonLink>
        </div>
      </div>
    </SectionBanner>
  );
};

const HomepageBanner = () => {
  const { watchNow } = useHomepage();
  return (
    <SectionBanner
      banner={{
        cover: watchNow?.cover,
        title: watchNow.title,
        body: watchNow.body,
      }}
    >
      <div className="flex flex-col mt-4 sm:flex-row gap-x-6">
        <div>
          <ButtonLink
            to="/watch-now"
            filled
            className="inline-flex items-center py-1 pl-4 pr-8 space-x-2 font-roboto md:py-2"
          >
            <img src={PlayIcon} alt="Play" className="w-7 h-7" />
            <div className="text-sm text-left text-black md:text-lg">
              <div className="font-bold leading-5">{watchNow?.button}</div>
            </div>
          </ButtonLink>
        </div>
      </div>
    </SectionBanner>
  );
};

export const WatchNowBanner = ({ animate }: { animate?: boolean }) => {
  const { ticketsUrl } = useRegion();

  if (ticketsUrl) return <TicketsBanner />;

  return <HomepageBanner />;
};

// <Banner src={watchNowBanner?.cover?.localFile.childImageSharp.gatsbyImageData} alt="Watch now" animate={animate}>
{
  /* <div className="grid items-center w-3/4 h-full grid-cols-12 mx-auto my-10 text-white">
        <div className="col-span-10 col-start-2">
          <h3 className="mb-4 text-3xl font-bold">{watchNowBanner?.title}</h3>
          <p className="text-base md:text-lg font-roboto">{watchNowBanner?.body}</p>
          <div className="items-center justify-center block mt-6 sm:flex sm:justify-start">
            <ButtonLink to="/watch-now" filled className="flex-shrink-0 py-1 pl-4 pr-8 md:py-2">
              <div className="flex items-center space-x-1 md:space-x-3 font-roboto">
                <img src={PlayIcon} alt="Play" className="w-7 h-7" />
                <div className="text-sm text-left text-black md:text-lg">
                  <div className="font-bold leading-5">{watchNowBanner?.button}</div>
                </div>
              </div>
            </ButtonLink>
            {pantaflixPlayerUrl && (
              <div className="flex items-center flex-shrink-0 mt-4 space-x-2 sm:mt-0 sm:ml-6">
                <img src={Visa} alt="visa" className="w-8 h-6" />
                <img src={Mastercard} alt="mastercard" className="w-8 h-6" />
                <img src={Amex} alt="amex" className="w-8 h-6" />
              </div>
            )}
          </div>
        </div>
      </div> */
}
// </Banner>
