import React, { ReactNode, useEffect, useState } from "react";
import Banner from "../components/Banner";
import Button, { ButtonLink } from "../components/Button";
import { HomepageBanner } from "../components/Homepage/Banner";
import { CastList } from "../components/Homepage/HomepageCast";
import { Reviews } from "../components/Homepage/Reviews";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Socials from "../components/Socials";
import DownArrow from "../images/down-arrow.svg";
import { Screenings } from "../components/Screenings";
import { motion } from "framer-motion";
import { useFilteredByLocale, useWindowDimensions } from "../hooks";
import { MovieCard } from "../components/MovieCard";
import { StayInTouch } from "../components/StayinTouch";
import { WatchNowBanner } from "../components/WatchNowBanner";
import { CommonTranslation, useCommonTranslations } from "../components/CommonTranslations";
import { graphql, useStaticQuery } from "gatsby";
import { useRegion } from "../components/Region";
import cx from "classnames";
import { FestivalForm } from "./fight-for-love";
import ShopBanner from "../components/Homepage/ShopBanner";
import TheBookBanner from "../components/Homepage/TheBookBanner";

export default function Index({ location }: { location: Location }) {
  const [pastBanner, setPastBanner] = useState<boolean>(false);
  const commonTranslations = useCommonTranslations();

  return (
    <Layout
      location={location}
      headerProps={{ fixed: true, filled: pastBanner, animateFilled: true, logo: pastBanner, animateLogo: true }}
    >
      <Seo title={commonTranslations.home} />
      <Body pastBanner={pastBanner} setPastBanner={setPastBanner} />
    </Layout>
  );
}

const Body = ({ pastBanner, setPastBanner }: { pastBanner: boolean; setPastBanner(b: boolean): void }) => {
  const { height } = useWindowDimensions();

  const homepage = useHomepage();

  const handleScroll = () => {
    const h = height * 0.85;
    if (window.scrollY >= h && pastBanner !== true) {
      setPastBanner(true);
    } else if (window.scrollY < h && pastBanner !== false) {
      setPastBanner(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <>
      <HomepageBanner homepage={homepage} />
      <PageDownAndSocials show={!pastBanner} />
      <div id="banner" className="flex flex-col items-center text-white py-28">
        <div className="mx-auto text-center md:w-2/3">
          <h1 className="text-4xl font-bold">{homepage.banner.title}</h1>
          <p className="px-4 mt-6 text-2xl">{homepage.banner.body}</p>
        </div>
      </div>
      <Banners homepage={homepage} />
      <BookForFestival />
      <MovieCard homepage={homepage} />
      <div className="grid grid-cols-12">
        <StayInTouch className="col-span-10 col-start-2 mt-16 mb-28 md:px-10" />
      </div>
    </>
  );
};

const pageDownAndSocialsVariants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
};

const PageDownAndSocials = ({ show }: { show: boolean }) => {
  return (
    <motion.div
      className="relative overflow-visible"
      variants={pageDownAndSocialsVariants}
      initial="show"
      animate={show ? "show" : "hide"}
      transition={{ duration: 0.5, delay: 0 }}
    >
      <button onClick={() => document.getElementById("banner")?.scrollIntoView()}>
        <motion.div className="absolute px-6 py-2 -top-8" whileHover={{ top: "-1.5rem" }}>
          <img src={DownArrow} alt="down icon" />
        </motion.div>
      </button>
      <div className="absolute right-0 mr-6 -top-4">
        <div className="flex items-center mt-2 space-x-8">
          <Socials />
        </div>
      </div>
    </motion.div>
  );
};

const BookForFestival = () => {
  const { bookFirebirdTitle, bookFirebirdSubtitle, bookFirebirdText } = useHomepage();

  return (
    <div className="flex items-center justify-center w-full py-16 bg-black">
      <div className="flex flex-col w-3/4 mx-auto text-center text-white md:w-1/2 lg:text-left">
        <h1 className="mb-4 text-2xl font-bold">{bookFirebirdTitle}</h1>
        <p className="mb-4">{bookFirebirdText}</p>
        <h2 className="text-lg font-bold">{bookFirebirdSubtitle}</h2>
        <FestivalForm />
      </div>
    </div>
  );
};

const HomepageScreenings = () => {
  const { showTicketsPopup, ticketsUrl } = useRegion();
  return (
    <div className="py-20">
      <Screenings all={false} />
      <div className="flex items-center justify-center mt-8 space-x-4">
        <ButtonLink to="/festival-screenings" className="px-10 py-2">
          <CommonTranslation value="viewMore" />
        </ButtonLink>
        {!showTicketsPopup && (
          <ButtonLink to="/watch-now" className="px-10 py-2" filled>
            <CommonTranslation value="watchNow" />
          </ButtonLink>
        )}
        {showTicketsPopup && (
          <ButtonLink to={ticketsUrl ?? ""} className="px-10 py-2" filled>
            <CommonTranslation value="getTickets" />
          </ButtonLink>
        )}
      </div>
    </div>
  );
};

const Banners = ({ homepage }: { homepage: Homepage }) => {
  const { sergeysStory, directorsVision, behindTheScenes, fightForLove } = homepage;
  return (
    <>
      <WatchNowBanner />
      <TheBookBanner />
      <ShopBanner />
      <Reviews homepage={homepage} />
      <CastList />
      <SectionBanner banner={sergeysStory}>
        <div className="mt-8">
          <ButtonLink className="inline py-2" to="/sergeys-story">
            <CommonTranslation value="readMore" />
          </ButtonLink>
        </div>
      </SectionBanner>

      <SectionBanner banner={directorsVision}>
        <div className="mt-8">
          <ButtonLink className="inline py-2" to="/directors-vision">
            <CommonTranslation value="readMore" />
          </ButtonLink>
        </div>
      </SectionBanner>
      <SectionBanner banner={behindTheScenes}>
        <div className="mt-8">
          <ButtonLink className="inline py-2" to="/behind-the-scenes">
            <CommonTranslation value="viewMore" />
          </ButtonLink>
        </div>
      </SectionBanner>
      <SectionBanner banner={fightForLove}>
        <div className="mt-8">
          <ButtonLink className="inline py-2 border-primaryDark bg-primaryDark" to="/fight-for-love">
            <CommonTranslation value="readMore" />
          </ButtonLink>
        </div>
      </SectionBanner>
    </>
  );
};

interface SectionBannerProps {
  banner: Banner;
  titleClassName?: string;
  bodyClassName?: string;
  children: ReactNode;
}

export const SectionBanner = ({ banner, children, bodyClassName, titleClassName }: SectionBannerProps) => {
  return (
    <Banner src={banner.cover.localFile.childImageSharp.gatsbyImageData} alt={banner.title}>
      <div className="w-5/6 mx-auto my-10 text-white sm:w-3/4">
        <h3 className={cx("text-3xl font-bold mb-4", titleClassName)}>{banner.title}</h3>
        <p className={cx("text-lg font-roboto", bodyClassName)}>{banner.body}</p>
        {children}
      </div>
    </Banner>
  );
};

export const useHomepage = (): Homepage => {
  const {
    allStrapiHomepage: { edges },
  } = useStaticQuery<{ allStrapiHomepage: EdgesQuery<{ node: Homepage }> }>(query);

  return useFilteredByLocale(edges.map(({ node }) => node))[0];
};

const query = graphql`
  query HomepageQuery {
    allStrapiHomepage {
      edges {
        node {
          banner {
            body
            title
          }
          locale
          reviews {
            quote
            reviewer
            stars
            background {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
          }
          sergeysStory {
            body
            title
            cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
          }
          directorsVision {
            body
            title
            cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
          }
          behindTheScenes {
            body
            title
            cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
          }
          fightForLove {
            body
            title
            cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
          }
          theBook {
            title
            body
            button
            cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
          }
          shop {
            body
            title
            cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
          }
          shopUrl
          giveBackUrl
          synopsis
          laurels {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, formats: [AUTO, WEBP])
              }
            }
          }
          watchNow {
            title
            cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
            button
          }
          bookFirebirdTitle
          bookFirebirdSubtitle
          bookFirebirdText
        }
      }
    }
  }
`;
