import { useEffect, useState } from "react";

type Callback = () => void;

export function useKeyPress(targetKey: string, callback: Callback) {
  const upHandler = ({ key }: { key: string }) => {
    if (key === targetKey) {
      callback();
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keyup", upHandler);
    };
  }, []);
}
