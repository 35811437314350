import cx from "classnames";
import { Link } from "gatsby";
import React, { PropsWithChildren } from "react";
import { LocaleAwareLink } from "./LanguageMenu";

interface ButtonProps {
  filled?: boolean;
  border?: boolean;
  className?: string;
  onClick?(): void;
}

export default function Button({
  filled = false,
  border = true,
  className = "",
  onClick,
  children,
  ...props
}: PropsWithChildren<ButtonProps & JSX.IntrinsicElements["button"]>) {
  return (
    <button
      type="button"
      className={cx(
        "rounded-[0.875rem] font-bold py-1 px-7 text-sm flex items-center",
        {
          "text-white": !filled,
          "bg-white text-black": filled,
          "border-2 border-white": border,
        },
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}

export function ButtonLink({
  filled = false,
  border = true,
  className = "",
  to,
  children,
  target,
}: PropsWithChildren<ButtonProps & { to: string; target?: string }>) {
  return (
    <LocaleAwareLink
      className={cx(
        "block rounded-[0.875rem] font-bold py-1 px-7 text-sm items-center",
        {
          "text-white": !filled,
          "bg-white text-black": filled,
          "border-2 border-white": border,
        },
        className
      )}
      target={target}
      to={to}
    >
      {children}
    </LocaleAwareLink>
  );
}
