import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useCommonTranslations } from "../components/CommonTranslations";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StayInTouch } from "../components/StayinTouch";
import { useFilteredByLocale } from "../hooks";

const firebaseConfig = {
  apiKey: "AIzaSyD-KLsrwvTJpq_fNRsqmzoi1R682vuTWk4",
  authDomain: "firebird-8a726.firebaseapp.com",
  projectId: "firebird-8a726",
  storageBucket: "firebird-8a726.appspot.com",
  messagingSenderId: "247970633058",
  appId: "1:247970633058:web:1e5792454fd18e9dbf083b",
};

const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore();

export default function PressAndContact({ location }: { location: Location }) {
  const commonTranslations = useCommonTranslations();
  return (
    <Layout location={location} headerProps={{ fixed: true, filled: true, logo: true }}>
      <Seo title={commonTranslations.pressAreaAndContact} />
      <Body />
    </Layout>
  );
}

const Body = () => {
  const { contactEmails, pleaseFindOurPressMaterialsHere, helpCenterTitle, helpCenterBody } = usePressAndContact();

  return (
    <div className="grid grid-cols-12 col-start-2 min-h-screen w-full pt-16 press-and-contacts pb-20">
      <div className="grid grid-cols-12 col-start-2 col-span-10 text-white pt-20 gap-y-10">
        <div className="col-span-12 md:col-span-5 flex">
          <StayInTouch form />
        </div>
        <div className="col-span-12 md:col-span-4 md:col-start-8 flex flex-col space-y-10">
          <PressInquiries />
        </div>
        <div className="col-span-12 flex justify-center my-10">
          <div className="w-40 border-b border-white"></div>
        </div>
        <div className="col-span-12 md:col-span-5">
          <h1 className="font-bold text-3xl mb-8">{helpCenterTitle}</h1>
          <ReactMarkdown className="text-xl font-bold mb-6">{helpCenterBody}</ReactMarkdown>
        </div>
        <div className="col-span-12 md:col-span-4 md:col-start-8">
          <div className="flex flex-col space-y-2">
            {contactEmails.map(({ email, title }) => (
              <EmailCard email={email} info={title} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const PressInquiries = () => {
  const { pressInquiries, pressInquiriesSubtext, pressInquiryEmails, pleaseFindOurPressMaterialsHere } =
    usePressAndContact();
  return (
    <div className="flex flex-col space-y-12">
      <h2 className="text-3xl font-bold">{pressInquiries}</h2>
      <div>
        <h3 className="text-lg font-bold mb-3">{pressInquiriesSubtext}</h3>
        <div className="flex flex-col gap-y-2">
          {pressInquiryEmails.map((props) => (
            <EmailCard {...props} />
          ))}
        </div>
      </div>
      <div>
        <ReactMarkdown className="text-xl font-bold mb-6">{pleaseFindOurPressMaterialsHere}</ReactMarkdown>
      </div>
    </div>
  );
};

interface EmailCardProps {
  info: string;
  email: string;
  name?: string;
}

const EmailCard = ({ info, email, name }: EmailCardProps) => {
  return (
    <a
      href={`mailto:${email}`}
      target="_blank"
      className="flex flex-col border-2 border-white rounded-md p-2 hover:bg-primaryDark hover:bg-opacity-25 !no-underline"
    >
      <p className="text-xl font-medium pr-2">{info}</p>
      <div className="flex justify-between text-sm flex-wrap">
        <span>{email}</span>
        <span className="font-bold">{name}</span>
      </div>
    </a>
  );
};

const usePressAndContact = (): PressAndContact => {
  const {
    allStrapiPressAreaAndContact: { edges },
  } = useStaticQuery<{ allStrapiPressAreaAndContact: EdgesQuery<{ node: PressAndContact }> }>(query);

  return useFilteredByLocale(edges.map(({ node }) => node))[0];
};

const query = graphql`
  query PressAndContact {
    allStrapiPressAreaAndContact {
      edges {
        node {
          title
          subTitle
          pressInquiries
          pressInquiriesSubtext
          pressInquiryEmails {
            email
            info
            name
          }
          name
          pleaseFindOurPressMaterialsHere
          request
          send
          locale
          contactEmails {
            email
            title
          }
          helpCenterTitle
          helpCenterBody
        }
      }
    }
  }
`;
