import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import LeftArrow from "../../images/left-arrow.svg";
import RightArrow from "../../images/right-arrow.svg";
import StarIcon from "../../images/star.svg";

export const Reviews = ({ homepage }: { homepage: Homepage }) => {
  return (
    <div className="w-full relative">
      <Carousel
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        swipeable={true}
        transitionTime={1000}
        renderArrowPrev={(onClick) => (
          <div className="top-0 left-0 absolute h-full items-center flex z-30">
            <button type="button" onClick={onClick} className="flex items-center p-4">
              <img alt="arrow left" src={LeftArrow} />
            </button>
          </div>
        )}
        renderArrowNext={(onClick) => (
          <div className="top-0 right-0 absolute h-full items-center flex z-30">
            <button type="button" onClick={onClick} className="flex items-center p-4">
              <img alt="arrow right" src={RightArrow} />
            </button>
          </div>
        )}
      >
        {homepage.reviews.map(
          (
            {
              reviewer,
              quote,
              background: {
                localFile: {
                  childImageSharp: { gatsbyImageData },
                },
              },
            },
            index
          ) => (
            <div className="relative" key={index}>
              <GatsbyImage image={gatsbyImageData} alt={quote} className="w-full object-cover h-[50vh] opacity-70" />

              <div className="absolute top-0 w-full h-full flex justify-center items-center">
                <div className="text-right w-1/2 md:w-2/3">
                  <div className="italic mt-5 text-2xl font-light text-white">"{quote}"</div>
                  <div className="mt-3 font-bold text-white text-xl">{reviewer}</div>
                </div>
              </div>
            </div>
          )
        )}
      </Carousel>
    </div>
  );
};
