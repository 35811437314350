import { AnimatePresence, motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { useFilteredByLocale } from "../hooks";
import useOnClickOutside from "../utils/use-click-outside";

export const DonateContext = createContext({ open: false, setOpen: () => {} } as {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
});

export const useDonate = () => {
  return useContext(DonateContext);
};

export const DonateContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [open, setOpen] = useState(false);

  const context = useMemo(() => {
    return {
      open,
      setOpen,
    };
  }, [open, setOpen]);

  return <DonateContext.Provider value={context}>{children}</DonateContext.Provider>;
};

const variants = {
  active: {
    background: "rgba(0, 0, 0, 1)",
    transition: { staggerChildren: 0.2 },
  },
  inactive: {
    background: "rgba(0, 0, 0, 0)",
  },
};

const childVariants = {
  active: {
    opacity: 1,
  },
  inactive: {
    opacity: 0,
  },
};

export const Donate = () => {
  const { open, setOpen } = useDonate();

  const [{ firstMessage, secondMessage, thankYou }] = useDonateTexts();

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className="fixed z-30 top-16 right-0 text-white md:w-[33vw] rounded-lg p-4 text-center md:mr-4 mx-4"
          variants={variants}
          initial="inactive"
          animate={open ? "active" : "inactive"}
        >
          <motion.p variants={childVariants}>{firstMessage}</motion.p>
          <motion.div className="flex justify-center items-center py-8" variants={childVariants}>
            <a
              className="rounded-md border border-white p-2"
              href="https://www.paypal.com/donate?hosted_button_id=FSM2UHWUCD37E"
              target="_blank"
            >
              <StaticImage className="w-20" src="../images/paypal.png" alt="paypal" />
            </a>
          </motion.div>
          <motion.p className="font-bold" variants={childVariants}>
            {secondMessage}
          </motion.p>
          <motion.p className="font-bold mt-4" variants={childVariants}>
            {thankYou}
          </motion.p>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const useDonateTexts = () => {
  const {
    allStrapiDonate: { edges },
  } = useStaticQuery<{ allStrapiDonate: EdgesQuery<{ node: DonateTexts }> }>(query);

  return useFilteredByLocale(edges.map(({ node }) => node)) as DonateTexts[];
};

const query = graphql`
  query DonateQuery {
    allStrapiDonate {
      edges {
        node {
          firstMessage
          secondMessage
          thankYou
          locale
        }
      }
    }
  }
`;
