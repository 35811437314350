import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Button, { ButtonLink } from "./Button";
import { CommonTranslation } from "./CommonTranslations";
import LanguageMenu, { LocaleAwareLink } from "./LanguageMenu";
import Logo from "./Logo";
import Duplicate from "../images/icons/duplicate.svg";
import Twitter from "../images/icons/twitter.svg";
import Facebook from "../images/icons/facebook.svg";
import Pinterest from "../images/icons/pinterest.svg";
import Tumblr from "../images/icons/tumblr.svg";
import Email from "../images/icons/email.svg";
import Telegram from "../images/icons/telegram.svg";
import { MenuButton, useMenu } from "./Menu";
import ShareIcon from "../images/share.svg";
import ArrowBack from "../images/icons/arrow-back.svg";
import Heart from "../images/icons/heart.svg";
import { Link } from "gatsby";
import useOnClickOutside from "../utils/use-click-outside";
import { Donate, useDonate } from "./Donate";
import { useRegion } from "./Region";
import { useHomepage } from "../pages";

export interface HeaderProps {
  fixed?: boolean;
  showButtons?: boolean;
  filled?: boolean;
  animateFilled?: boolean;
  animateLogo?: boolean;
  className?: string;
  logo?: boolean;
}

const filledVariants = {
  filled: {
    background: "rgba(0, 0, 0, 1)",
  },
  transparent: {
    background: "rgba(0, 0, 0, 0)",
  },
};

const logoVariants = {
  show: {
    opacity: 1,
    transition: {
      duration: 0.75,
      delay: 0.25,
    },
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 0,
    },
  },
};

const Header = ({
  fixed = false,
  showButtons = true,
  filled = false,
  logo = false,
  animateFilled = false,
  animateLogo = false,
  className,
  location,
}: HeaderProps & { location: Location }) => {
  const showBack = location && location.pathname !== "/";
  const { pantaflixPlayerUrl, showTicketsPopup, ticketsUrl, streamingPlatforms } = useRegion();

  return (
    <motion.header
      className={cx(
        "w-full flex flex-1 justify-between pl-8 pr-2 md:px-8 z-50 items-center text-white h-16",
        {
          fixed,
        },
        className
      )}
      variants={filledVariants}
      initial={filled ? "filled" : "transparent"}
      animate={filled ? "filled" : "transparent"}
      transition={animateFilled ? { duration: 0.5 } : { duration: 0 }}
    >
      <div className="relative flex items-center space-x-4">
        <MenuButton />
        <LanguageMenu />
        {showBack && (
          <div>
            <LocaleAwareLink to="/" className="flex items-center font-roboto md:ml-2">
              <img src={ArrowBack} className="w-5 h-5" />
              <span className="ml-1 font-semibold uppercase">
                <CommonTranslation value="home" />
              </span>
            </LocaleAwareLink>
          </div>
        )}
      </div>
      {showButtons && (
        <MenuStateAwareComponent>
          <div className="flex items-center justify-end flex-shrink-0 space-x-2 md:justify-start">
            <LocaleAwareLink to="/">
              <motion.div
                variants={logoVariants}
                initial={logo ? "show" : "hide"}
                animate={logo ? "show" : "hide"}
                className="items-center justify-center hidden w-32 py-4 mr-6 md:w-48 lg:flex"
              >
                <Logo />
              </motion.div>
            </LocaleAwareLink>
            {!showTicketsPopup && location && !location?.pathname.includes("/watch-now") && (
              <ButtonLink className="hidden md:block" to="/watch-now" filled={false}>
                <CommonTranslation
                  value={
                    pantaflixPlayerUrl || (streamingPlatforms && streamingPlatforms.length > 0)
                      ? "watchNow"
                      : "getNotified"
                  }
                />
              </ButtonLink>
            )}
            {showTicketsPopup && (
              <ButtonLink className="hidden uppercase md:block" to={ticketsUrl ?? ""} filled={false}>
                <CommonTranslation value="getTickets" />
              </ButtonLink>
            )}
            <div>
              <div className="flex ml-2 space-x-2">
                <TheBook />
                <Shop />
                <FightForLoveButton />
              </div>
            </div>
          </div>
        </MenuStateAwareComponent>
      )}
    </motion.header>
  );
};

const TheBook = () => {
  return (
    <ButtonLink
      to="/the-book"
      className="hidden px-4 uppercase rounded-md md:flex bg-primary hover:bg-opacity-80"
      border={false}
    >
      <span className="z-10">
        <CommonTranslation value="theBook" />
      </span>
    </ButtonLink>
  );
};

const Shop = () => {
  const { shopUrl } = useHomepage();
  return (
    <ButtonLink
      to={shopUrl}
      className="hidden px-4 uppercase rounded-md md:flex hover:bg-white hover:bg-opacity-20"
      border={false}
    >
      <span className="z-10">
        <CommonTranslation value="shop" />
      </span>
    </ButtonLink>
  );
};

const MenuStateAwareComponent = ({ children }: React.PropsWithChildren<{}>) => {
  const { open } = useMenu();

  if (open) return null;

  return <>{children}</>;
};

const shareVariants = {
  hidden: {
    background: "rgba(0, 0, 0, 0)",
  },
  shown: {
    background: "rgba(0, 0, 0, 1)",
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const shareItemVariants = {
  hidden: {
    opacity: 0,
  },
  shown: {
    opacity: 1,
  },
};

const Share = () => {
  const [shareOpen, setShareOpen] = React.useState<boolean>(false);
  const ref = React.useRef(null);
  useOnClickOutside(ref, () => setShareOpen(false));

  return (
    <div ref={ref} className="relative">
      <AnimatePresence>
        {shareOpen && (
          <motion.div
            className="absolute left-0 w-24 px-4 py-4 rounded-lg top-8 border-1"
            variants={shareVariants}
            initial="hidden"
            animate={shareOpen ? "shown" : "hidden"}
          >
            <motion.div className="flex items-center justify-center space-x-2 text-white">
              <motion.a
                href="https://twitter.com/intent/tweet?url=https%3A%2F%2Ffirebirdmovie.com&text=Watch%20FIREBIRD%20with%20me%21"
                target="_blank"
              >
                <motion.img className="w-5 h-4 text-white" variants={shareItemVariants} src={Twitter} />
              </motion.a>
              <motion.a
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffirebirdmovie.com"
                target="_blank"
              >
                <motion.img className="w-5 h-4 text-white" variants={shareItemVariants} src={Facebook} />
              </motion.a>
              <motion.a
                href="https://pinterest.com/pin/create/button/?url=https%3A%2F%2Ffirebirdmovie.com&amp;media=&amp;description=Watch%20FIREBIRD%20with%20me!"
                target="_blank"
              >
                <motion.img className="w-5 h-4 text-white" variants={shareItemVariants} src={Pinterest} />
              </motion.a>
            </motion.div>
            <motion.div className="flex items-center justify-center mt-2 space-x-2 text-white">
              <motion.a
                href="https://www.tumblr.com/widgets/share/tool?posttype=link&amp;canonicalUrl=https%3A%2F%2Ffirebirdmovie.com&amp;title=&amp;caption=Watch%20FIREBIRD%20with%20me!"
                target="_blank"
              >
                <motion.img className="w-5 h-4 text-white" variants={shareItemVariants} src={Tumblr} />
              </motion.a>
              <motion.a
                href="mailto:?subject=%20&amp;body=https%3A%2F%2Ffirebirdmovie.com%0A%0AWatch%20FIREBIRD%20with%20me!"
                target="_blank"
              >
                <motion.img className="w-6 h-6 text-white" variants={shareItemVariants} src={Email} />
              </motion.a>
              <motion.a
                href="https://telegram.me/share/url?url=https://www.firebirdmovie.com/&text=Watch%20FIREBIRD%20with%20me"
                target="_blank"
              >
                <motion.img className="w-5 h-4 text-white" variants={shareItemVariants} src={Telegram} />
              </motion.a>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <Button className="px-2" border={false} onClick={() => setShareOpen(!shareOpen)}>
        <span className="z-10 hidden md:flex">
          <CommonTranslation value="share" />
        </span>{" "}
        <img src={ShareIcon} className="w-5 h-5 m-0 ml-1" alt="share icon" />
      </Button>
    </div>
  );
};

const FightForLoveButton = () => {
  return (
    <ButtonLink className="px-2 rounded-md bg-primaryDark" to="/fight-for-love" border={false}>
      <CommonTranslation value="fightForLove" />
    </ButtonLink>
  );
};

const DonateButton = () => {
  const { open, setOpen } = useDonate();
  const ref = React.useRef(null);
  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div ref={ref} className="relative hidden md:flex">
      <Button className="px-2" border={false} onClick={() => setOpen(!open)}>
        <span className="z-10">
          <CommonTranslation value="donate" />
        </span>{" "}
        <img src={Heart} className="w-5 h-5 m-0 ml-1" alt="donate icon" />
      </Button>
      <Donate />
    </div>
  );
};

export default Header;
