import { addYears } from "date-fns";
import React from "react";
import { useCookies } from "react-cookie";
import Button from "./Button";
import { useLocale } from "./LanguageMenu";

export default function CookieConsent() {
  const [{ cookie_consent }, setCookie] = useCookies(["cookie_consent"]);
  const locale = useLocale();

  if (cookie_consent) return null;

  return (
    <div className="w-full fixed text-sm bottom-0 md:left-1/2 md:text-base md:-translate-x-1/2 bg-black z-[60] text-white h-14 flex justify-between items-center px-4 md:w-5/6 lg:w-1/2 rounded-lg">
      <p>
        {locale == "de"
          ? "Wir verwenden cookies, um ihre erfahrung auf unserer website zu verbessern!"
          : "We use cookies to improve your experience on our site!"}
      </p>
      <Button onClick={() => setCookie("cookie_consent", "yes", { expires: addYears(new Date(), 1) })}>OK</Button>
    </div>
  );
}
