import React, { PropsWithChildren, ReactNode } from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbyImageProps } from "gatsby-plugin-image";

interface BannerProps {
  src: GatsbyImageProps["image"];
  alt: string;
  className?: string;
  animate?: boolean;
  imageClassName?: string;
}

const variants = {
  hover: {
    scale: 1.075,
    opacity: 1,
  },
  rest: { scale: 1, filter: "brightness(0.75)", opacity: 0.6 },
};

export default function Banner({
  src,
  alt,
  children,
  animate = true,
  className,
  imageClassName,
}: PropsWithChildren<BannerProps>) {
  return (
    <motion.div className="overflow-hidden" whileHover="hover" initial="rest">
      <motion.div className={cx("w-full h-screen sm:h-[50vh] flex items-center relative", className)}>
        <motion.div className="w-full h-full" transition={{ duration: 0.7 }} variants={variants}>
          <GatsbyImage className={cx("object-cover h-full w-full", imageClassName)} image={src} alt={alt} />
        </motion.div>
        <div className="w-full md:w-2/3 lg:w-1/2 h-full absolute top-0 flex items-center">{children}</div>
      </motion.div>
    </motion.div>
  );
}
