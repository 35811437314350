import React, { useState } from "react";
import { ButtonLink } from "../Button";
import { CommonTranslation, useCommonTranslations } from "../CommonTranslations";
import { LogoWithAuthor } from "../Logo";
import { useRegion } from "../Region";
import Laurels from "./Laurels";
import Video from "../../images/cover-video.mp4";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import WatchTrailer from "../WatchTrailer";

const videoVariants = {
  hidden: { opacity: 0 },
  shown: { opacity: 1 },
};

export const HomepageBanner = ({ homepage }: { homepage: Homepage }) => {
  const { inTheatersAndOnDemand, pantaflixPlayerUrl, showTicketsPopup, ticketsUrl, streamingPlatforms } = useRegion();
  const [videoLoaded, setVideoLoaded] = useState<boolean>(true);

  const laurels = homepage.laurels.map((laurel) => laurel.localFile?.childImageSharp.gatsbyImageData);
  const { watchNow } = useCommonTranslations();

  return (
    <div className="w-full h-[98vh] relative flex flex-1 items-end justify-center">
      <StaticImage className="w-full h-[98vh] object-cover" alt="Banner" src="../../images/banner.png" />
      <motion.video
        variants={videoVariants}
        animate={videoLoaded ? "shown" : "hidden"}
        initial="hidden"
        autoPlay
        muted
        loop
        playsInline
        webkit-playsinline
        preload="auto"
        className="absolute top-0 w-full h-[98vh] object-cover flex pointer-events-none"
        onCanPlay={() => setVideoLoaded(true)}
        transition={{ duration: 0.75 }}
      >
        <motion.source variants={videoVariants} src={Video} type="video/mp4" />
      </motion.video>
      <div className="absolute flex flex-col items-center justify-center">
        <div className="flex flex-wrap items-center justify-center mb-4 space-x-4">
          <Laurels laurels={laurels} />
        </div>
        <div className="w-[80%] md:w-1/2">
          <LogoWithAuthor />
        </div>
        <p className="my-6 font-sans text-xs text-center text-white uppercase md:text-xl md:my-8">
          {inTheatersAndOnDemand}
        </p>
        {!showTicketsPopup && (
          <ButtonLink filled className="flex h-10 px-10" to="/watch-now">
            <CommonTranslation
              value={
                pantaflixPlayerUrl || (streamingPlatforms && streamingPlatforms.length > 0) ? "watchNow" : "getNotified"
              }
            />
          </ButtonLink>
        )}
        {showTicketsPopup && (
          <ButtonLink filled className="flex h-10 px-10 uppercase" to={ticketsUrl ?? ""}>
            <CommonTranslation value="getTickets" />
          </ButtonLink>
        )}

        <WatchTrailer />
        <div className="mb-20 md:hidden" />
      </div>
    </div>
  );
};
