import React from "react";
import { CommonTranslation } from "./CommonTranslations";
import { LocaleAwareLink } from "./LanguageMenu";
import { useRegion } from "./Region";
import cx from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";

export const MovieCard = ({ homepage: { synopsis } }: { homepage: Homepage }) => {
  const { moviePoster } = useRegion();
  return (
    <div className="grid w-full grid-cols-12 px-4 text-white">
      <div className="col-span-10 col-start-2 md:col-span-8 md:col-start-3">
        <div className="mb-8 text-3xl font-bold">
          <CommonTranslation value="firebird" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-6 lg:grid-cols-5 xl:grid-cols-5 xl:gap-14 2xl:gap-16">
          <div className="order-1 mb-4 md:mb-0 lg:col-span-2 xl:col-span-2">
            {moviePoster && (
              <GatsbyImage image={moviePoster.localFile?.childImageSharp?.gatsbyImageData} alt="Firebird cover" />
            )}
            <Credits className="hidden mt-4 md:grid md:grid-cols-2 md:gap-4 lg:hidden" />
          </div>
          <div className="flex flex-col order-3 mt-4 space-y-6 md:mt-0 md:order-2 lg:col-span-2 xl:col-span-2">
            <p>{synopsis}</p>
            <p>
              <CommonTranslation value="basedOnATrueStory" />
              <br />
              <LocaleAwareLink to="/sergeys-story" className="text-[#409DE4] underline font-bold">
                <CommonTranslation value="findOutMore" />
              </LocaleAwareLink>
            </p>
          </div>
          <Credits className="grid order-3 grid-cols-2 gap-4 my-4 mt-8 md:order-3 md:hidden lg:mt-0 lg:flex lg:flex-col lg:col-span-1" />
        </div>
      </div>
    </div>
  );
};

const Credits = ({ className }: { className: string }) => (
  <div className={cx("text-sm md:text-base", className)}>
    <div>
      <h5 className="text-[#409DE4] font-bold">
        <CommonTranslation value="directedBy" />
      </h5>
      <h6>Peeter Rebane</h6>
    </div>
    <div>
      <h5 className="text-[#409DE4] font-bold">
        <CommonTranslation value="writtenAndProducedBy" />
      </h5>
      <h6>Peeter Rebane</h6>
      <h6>Tom Prior</h6>
    </div>
    <div>
      <h5 className="text-[#409DE4] font-bold">
        <CommonTranslation value="producedBy" />
      </h5>
      <h6>Brigita Rozenbrika</h6>
      <h6>Dankuro Shinma</h6>
      <h6>Orlan Boston</h6>
      <h6>Christopher Racster</h6>
    </div>
    <div>
      <h5 className="text-[#409DE4] font-bold">
        <CommonTranslation value="starring" />
      </h5>
      <h6>Tom Prior</h6>
      <h6>Oleg Zagorodnii</h6>
      <h6>Diana Pozharskaya</h6>
    </div>
  </div>
);
