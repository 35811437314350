import React from "react";
import cx from "classnames";

interface InputProps {
  label?: string;
  containerClass?: string;
  helperText?: string;
}

export const Input = ({ label, containerClass, helperText, ...props }: JSX.IntrinsicElements["input"] & InputProps) => {
  return (
    <div className={cx("relative border-2 border-white h-14 w-full", containerClass)}>
      {label && <label className="absolute -top-6 text-white ml-2 font-bold">{label}</label>}
      <input className="w-full bg-black h-full px-2 text-3xl" {...props} />
      <div className="text-xs mt-1 md:text-sm ml-1 md:mt-2">{helperText}</div>
    </div>
  );
};

export const TextArea = ({
  label,
  containerClass,
  helperText,
  ...props
}: JSX.IntrinsicElements["textarea"] & InputProps) => {
  return (
    <div>
      {label && <label className="text-white ml-2 font-bold">{label}</label>}
      <textarea className="p-1 mt-1 border-2 border-white w-full bg-black h-full px-2 text-3xl" {...props} />
    </div>
  );
};
