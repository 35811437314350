import cx from "classnames";
import React, { ReactNode, useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Button from "./Button";
import { useCommonTranslations } from "./CommonTranslations";
import { Input } from "./Input";
import Check from "../images/icons/check.svg";

export const StayInTouch = ({
  className,
  withoutTitle = false,
  formClassName,
  form,
}: {
  className?: string;
  withoutTitle?: boolean;
  formClassName?: string;
  form?: boolean;
}) => {
  const commonTranslations = useCommonTranslations();

  const Form = form ? CustomForm2 : CustomForm;

  return (
    <div className={cx("text-white w-full md:w-5/6 flex flex-col justify-center items-center mx-auto", className)}>
      {!withoutTitle && (
        <div className="text-left md:text-center mb-16 w-full md:px-2">
          <h3 className="text-3xl font-bold mb-8">{commonTranslations.stayInTouch}</h3>
          <p>{commonTranslations.stayInTouchSubtext}</p>
        </div>
      )}
      <MailchimpSubscribe
        url="https://firebirdmovie.us17.list-manage.com/subscribe/post?u=07333a002aec8adfcfdd6df26&amp;id=996472485f"
        render={(props) => <Form className={formClassName} {...props} />}
      />
    </div>
  );
};

export const CustomForm2 = ({
  subscribe,
  status,
  message,
  className,
}: {
  subscribe: any;
  status: any;
  message: any;
  className?: string;
}) => {
  const commonTranslations = useCommonTranslations();

  const [data, setData] = useState<{ FNAME?: string; EMAIL?: string; CITY?: string }>({
    CITY: "",
    EMAIL: "",
    FNAME: "",
  });

  const onSubmit = () => {
    if (!data.EMAIL) return;
    subscribe(data);
  };

  const onUpdate = (key: string, value: string) => {
    setData({ ...data, [key]: value });
  };

  return (
    <div className={className ? className : "flex flex-col space-y-8 items-center px-4"}>
      <Input
        name="EMAIL"
        type="email"
        label={commonTranslations.email}
        helperText={commonTranslations.onlyEmailIsMandatory}
        containerClass=""
        required
        onChange={({ target: { value } }) => onUpdate("EMAIL", value)}
      />
      <Input
        name="CITY"
        label={commonTranslations.city}
        containerClass="!mt-14"
        onChange={({ target: { value } }) => onUpdate("CITY", value)}
      />
      <input type="hidden" name="gdpr[87295]" value="Y" />
      <div className="flex justify-center mt-8">
        <Button className="mx-auto py-2" filled onClick={onSubmit}>
          <span>{commonTranslations.subscribe}</span>{" "}
          {status === "success" && (
            <span className="flex-shrink-0 text-green-400">
              <img src={Check} className="h-4 w-4 ml-2" />
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

const CustomForm = ({
  subscribe,
  status,
  message,
  className,
}: {
  subscribe: any;
  status: any;
  message: any;
  className?: string;
}) => {
  const commonTranslations = useCommonTranslations();

  const [data, setData] = useState<{ FNAME?: string; EMAIL?: string; CITY?: string }>({
    CITY: "",
    EMAIL: "",
    FNAME: "",
  });

  const onSubmit = () => {
    if (!data.EMAIL) return;
    subscribe(data);
  };

  const onUpdate = (key: string, value: string) => {
    setData({ ...data, [key]: value });
  };

  return (
    <div
      className={
        className
          ? className
          : "flex flex-col space-y-8 md:grid md:space-y-0 md:grid-cols-6 lg:grid-cols-7 md:gap-4 items-center"
      }
    >
      <Input
        name="EMAIL"
        type="email"
        label={commonTranslations.email}
        helperText={commonTranslations.onlyEmailIsMandatory}
        containerClass="md:col-span-4"
        required
        onChange={({ target: { value } }) => onUpdate("EMAIL", value)}
      />
      <Input
        name="CITY"
        label={commonTranslations.city}
        containerClass="md:col-span-2 !mt-12 md:!mt-0"
        onChange={({ target: { value } }) => onUpdate("CITY", value)}
      />
      <input type="hidden" name="gdpr[87295]" value="Y" />
      <div className="flex col-span-8 lg:col-span-1 sm:!mt-4 lg:!mt-0">
        <Button className="mx-auto py-2" filled onClick={onSubmit}>
          <span>{commonTranslations.subscribe}</span>{" "}
          {status === "success" && (
            <span className="flex-shrink-0 text-green-400">
              <img src={Check} className="h-4 w-4 ml-2" />
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};
